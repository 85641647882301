import React, { useState } from 'react';
import { Dropdown } from 'antd';
import { MoreOutlined, LineChartOutlined, ExportOutlined } from '@ant-design/icons';
import './Styles.css';

const MenuMaisOpcoes = (props) => {
    const [abrirMenu, setAbrirMenu] = useState(false);
    const [clique, setClique] = useState(false); // Estado para controlar o clique

    const onMenuClick = () => {
        setAbrirMenu(!abrirMenu);
    };

    const onItemClick = (value) => {
        setAbrirMenu(false);
        if (props.onItemClick) {
            props.onItemClick(value.key, props.user);
        }
    };

    const items = [
        {
            key: 'ranking',
            label: 'Ver meu Ranking',
            icon: <LineChartOutlined />,
            onClick: onItemClick,
        },
        {
            key: 'sair',
            label: 'Sair da Fila',
            danger: true,
            icon: <ExportOutlined />,
            onClick: onItemClick,
        },
    ];

    const handleItemClick = () => {
        setClique(true);
        setTimeout(() => {
            setClique(false);
        }, 100);
    };

    const anchorClass = `ant-dropdown-link ${clique ? 'div-container-menu-mais-opcoes clicked' : 'div-container-menu-mais-opcoes'}`;

    return (
        <Dropdown
            menu={{
                items,
                onClick: onItemClick,
            }}
            open={abrirMenu}
        >
            <span onClick={handleItemClick} className={anchorClass}>
                <span>
                    <MoreOutlined className={`${anchorClass} menu-mais-opcoes`} onClick={onMenuClick} />
                </span>
            </span>
        </Dropdown>
    );
};

export default MenuMaisOpcoes;
