import React from 'react';
import { notification } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import './speedtest.css';

import conexaoBoa from '../../assets/conexao_boa.png';
import conexaoMedia from '../../assets/conexao_media.png';
import conexaoRuim from '../../assets/conexao_ruim.png';


const openNotification = () => {
  notification.open({
    message: 'Medidor de Internet',
    description: 'Uma conexão de internet ruim pode causar lentidão no carregamento do sistema e pode, também, resultar em erros ao exibir ou salvar dados.',
    duration: 5000,
  });
};

class SpeedTest extends React.Component {
  state = {
    interval: 1000 * 60 * 3,
    data: undefined,
    conexao: '',
    color: '',
    src: ''
  }

  constructor(props) {
    super(props);
    this.medirConexao = this.medirConexao.bind(this);
    this.getConexao = this.getConexao.bind(this);
    this.delay = this.delay.bind(this);
  }

  async componentDidMount() {
    this.getConexao();
  }

  async getConexao() {
    while (true) {
      const url = 'https://us-central1-bluve-4c-teste.cloudfunctions.net/ping';
      let time1 = new Date().getTime();
      await fetch(url + '?dummy=' + time1, { cache: 'no-cache' });
      let time2 = new Date().getTime();
      this.medirConexao(time2 - time1);

      await this.delay(this.state.interval);
    }
  }

  medirConexao(data) {
    if (data <= 300) {
      this.setState({ conexao: 'Boa', color: '#59DD43', src: conexaoBoa, interval: 1000 * 60 * 3 });
    } else if (data >= 500) {
      this.setState({ conexao: 'Ruim', color: '#FC3E3E', src: conexaoRuim, interval: 1000 * 60 * 1 });
    } else {
      this.setState({ conexao: 'Média', color: '#E7EB24', src: conexaoMedia, interval: 1000 * 60 * 3 });
    }
  }

  delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  render() {
    return (
      <div className='component'>
        <img className='speed-test-image' src={this.state.src} alt='Medidor de Conexão'></img>

        <div className='text'>
          <p>Internet {this.state.conexao}</p>
        </div>

        <QuestionCircleOutlined className='help' onClick={openNotification} />
      </div>
    )
  }
}

export default SpeedTest;
