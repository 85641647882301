import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'antd';
import { FallOutlined, PlusCircleOutlined, ShoppingCartOutlined, SwapOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { CSSTransition } from 'react-transition-group';
import './Styles.css';

const BotaoAcaoFlutuante = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [labelEncerrarAtendimento, setLabelEncerrarAtendimento] = useState('Encerrar atendimento');

    function handleResize() {
        if (window.innerWidth <= 400) {
            setLabelEncerrarAtendimento('Encerrar');
        } else {
            setLabelEncerrarAtendimento('Encerrar atendimento');
        }
    }

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const componentRef = useRef(null);
    let timeoutId;

    const handleButtonClick = () => {
        setIsOpen(!isOpen);
        resetTimeout();
    };

    const handleOptionClick = (option) => {
        setIsOpen(false);
        clearTimeout(timeoutId);

        if (props.onBotaoAcaoClick) {
            props.onBotaoAcaoClick(option, props.user);
        }
    };

    const handleClickOutside = (event) => {
        if (componentRef.current && !componentRef.current.contains(event.target)) {
            setIsOpen(false);
            clearTimeout(timeoutId);
        }
    };

    const resetTimeout = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            setIsOpen(false);
        }, 10000);
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        resetTimeout();

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <div className="floating-action-button" ref={componentRef}>

            <Button
                className="btn-adicionar-atendimento"
                shape="circle"
                type="link"
                onClick={() => handleOptionClick('Adicionar Atendimento')}
                //style={{ display: !isOpen ? 'block' : 'none' }}
            >
                <PlusCircleOutlined className="btn-adicionar-atendimento-icone" />
            </Button>


            <Button
                type="danger"
                className='floating-action-button-button'
                onClick={handleButtonClick}
            >{labelEncerrarAtendimento}</Button>
            <CSSTransition
                in={isOpen}
                timeout={10}
                classNames="fade"
                unmountOnExit
            >
                <div className="floating-action-button__options">
                    <Button
                        className='action-botao-venda'
                        shape="circle"
                        icon={<ShoppingCartOutlined className='botao-acao-icone' />}
                        onClick={() => handleOptionClick('vendeu')}
                    ><span className='botao-acao-label'>Venda</span></Button>
                    <Button
                        className='action-botao-nao-venda'
                        shape="circle"
                        icon={<FallOutlined className='botao-acao-icone' />}
                        onClick={() => handleOptionClick('nao-vendeu')}
                    ><span className='botao-acao-label'>Perda</span></Button>
                    <Button
                        className='action-botao-troca'
                        shape="circle"
                        icon={<SwapOutlined className='botao-acao-icone' />}
                        onClick={() => handleOptionClick('troca')}
                    ><span className='botao-acao-label'>Troca</span></Button>
                    <Button
                        className='action-botao-outros'
                        shape="circle"
                        icon={<UserSwitchOutlined className='botao-acao-icone' />}
                        onClick={() => handleOptionClick('outros')}
                    >
                        <span className='botao-acao-label'>Outros</span>
                    </Button>
                </div>
            </CSSTransition>
        </div>
    );
};

export default BotaoAcaoFlutuante;
