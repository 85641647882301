import React from 'react';
import { Tag, Select } from 'antd';
import './Motivos.css';

const { CheckableTag } = Tag;
const { Option } = Select;


const MotivosAtendimento = ({ experimentou, motivos, motivosSelecionados, handleMotivosSelectChange, handleMultiplosMotivosChange, handleExperimentouChange }) => {

    const motivosPerdaVenda = [];
    motivos.forEach((item) => {
        motivosPerdaVenda.push(
            <Option key={item.key} value={item.value}>
                {item.value}
            </Option>
        );
    });

    return (
        <div>
            <span className='motivos-span-selecionar-motivos'>O cliente experimentou algo com você?</span>
            <br></br><br></br>
            <Select
                placeholder="Informe"
                onChange={(value) => handleExperimentouChange(value)}
                style={{ width: 200 }}
                value={experimentou}
            >
                <Option value={true}>Sim</Option>
                <Option value={false}>Não</Option>
            </Select>
            <br></br><br></br>
            <br></br><br></br>
            <span className='motivos-span-selecionar-motivos'>Informe um ou mais motivos para a perda da venda</span>
            <br></br><br></br>

            {motivos.length > 10 ? (

                <Select
                    mode="multiple"
                    placeholder="Informe um ou mais motivos"
                    onChange={handleMultiplosMotivosChange}
                    style={{ width: 250 }}
                >
                    {motivosPerdaVenda}
                </Select>
            ) : (
                motivos.map(tag => (
                    <CheckableTag
                        className="motivosTag"
                        key={tag.key}
                        checked={motivosSelecionados.indexOf(tag) > -1}
                        onChange={(checked) => handleMotivosSelectChange(tag, checked)}
                    >
                        {tag.value}
                    </CheckableTag>
                ))
            )}


        </div>
    );
}

export default MotivosAtendimento;