import axios from './axios';


export default class API {
  async validateReCaptcha(token) {
    try {
      const route = `/validateReCaptcha/${token}`;

      const response = await request('post', route, {});
      return response.success;
    } catch (error) {
      console.log(error);
    }
  }
}

function request(method, route, params) {
  return new Promise(async (resolve, reject) => {
    let response;

    try {
      response = method === 'get' ?
        await axios[method](route, { params: { ...params } }) :
        await axios[method](route, { ...params });

      const res = method === 'get' ? response.data.result : response.data;

      resolve(res);
    } catch (error) {
      response = errorHandler(error);

      if (response) {
        resolve(response);
      } else {
        reject(error);
      }
    }
  })
}

function errorHandler(error) {
  console.log(error);

  if (error.response.data.code === 400) {
    return error.response.data;
  }

  return undefined;
}
