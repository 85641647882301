import React, { useState, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { Avatar, List, Button, Modal, Badge, Empty } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import IconFont from "../../components/iconFont/IconFont";
import { CustomDateFNS } from "../../components";
import SairDaFila from './SairFila/SairFila';
import { ausentarVendedor, editAtendimento, entrarNaFila, iniciarAtendimento } from '../../pages/fila/Functions-Fila';
import AdicionarVendedorFilaWrapper from './AdicionarVendedorFilaWrapper';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import MenuMaisOpcoes from './MenuMaisOpcoes/MenuMaisOpcoes';
import DiarioVendedor from '../../pages/diarioVendedor/DiarioVendedor';
import EncerrarAtendimento from './EncerrarAtendimento/EncerrarAtendimento';
import './VendedoresDisponiveis.css';
import BotaoAcaoFlutuante from '../BotaoAcao/BotaoAcao';


const { confirm } = Modal;

function VendedoresDisponiveis(props) {
  const [tipoEncerramento, setTipoEncerramento] = useState('');
  const [mostrarSaidaVendedor, setMostrarSaidaVendedor] = useState(false);
  const [vendedor, setVendedor] = useState({});
  const [mostrarAdicionarVendedor, setMostrarAdicionarVendedor] = useState(false);
  const [diarioVendedor, setDiarioVendedor] = useState({ id: undefined });
  const [mostrarEncerramento, setMostrarEncerramento] = useState(false);
  const [ultimoItemIndex, setUltimoItemIndex] = useState(null);

  useEffect(() => {
    if (props.state.dataFila.length > 0) {
      const ultimoItemIndex = props.state.dataFila.length - 1;
      setUltimoItemIndex(ultimoItemIndex);
    }
  }, [props.state.dataFila]);


  function adicionarAtendimento(obj) {

    const args = {
      self: props.self,
      user: obj.user,
    };

    confirm({
      title: "Adicionar atendimento",
      icon: <ExclamationCircleOutlined />,
      content:
        "Adicionar atendimento simultâneo para " + obj.user.apelido + "?",
      onOk() {
        editAtendimento(args);
      },
      onCancel() {
      },
    });
  }

  function btnIniciarAtendimentoClick(obj) {
    iniciarAtendimento(props.self, obj.user);
  }

  function fecharEncerrarAtendimento() {
    setMostrarEncerramento(false);
  }

  function formatarNome(nome) {
    const nomes = nome.split(' ');

    let nomeCompleto = '';

    for (let i of nomes) {
      const formatedName = i.charAt(0).toUpperCase() + i.slice(1).toLowerCase();
      nomeCompleto = nomeCompleto + ' ' + formatedName;
    }

    return nomeCompleto.substring(1);
  }

  function abrirModalAdicionarVendedor() {
    if (props.state.dataFila.length === 0) {
      setMostrarAdicionarVendedor(true);
    }
  }

  const onMenuMaisOpcoesClick = (item, user) => {
    if (item === 'sair') {
      setVendedor(user);
      setMostrarSaidaVendedor(true);
    } else if (item === 'ranking') {
      setDiarioVendedor({
        id: user.id,
        nome: user.nome,
        cpf: user.cpf,
        empresaId: props.self.state.user.id,
        visible: true,
        vendedores: props.self.state.dataFila.concat(props.self.state.dataAusente),
      });
    }
  };

  const onBotaoAcaoClick = (tipoAcao, usuario) => {
    if (tipoAcao === 'Adicionar Atendimento') {
      adicionarAtendimento({ user: usuario });
      return;
    }

    setTipoEncerramento(tipoAcao);
    setVendedor(usuario);
    setMostrarEncerramento(true);
  };


  const [, drop] = useDrop({
    accept: 'card',
    drop: async (item, monitor, component) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        return;
      }

      const cards = props.state.dataAusente.slice();
      const { index: dragIndex } = item;
      const { index: hoverIndex } = monitor.getItem();
      const draggedCard = cards[dragIndex];
      cards.splice(dragIndex, 1);
      cards.splice(hoverIndex, 0, draggedCard);

      await entrarNaFila(props.self, draggedCard, props.state.dataFila, props.state.dataAusente);

      if (props.self.state.config.exibirDesempenhoVendas) {
        setDiarioVendedor({
          id: draggedCard.id,
          nome: draggedCard.nome,
          cpf: draggedCard.cpf,
          empresaId: props.self.state.user.id,
          visible: true,
          vendedores: props.self.state.dataFila.concat(props.self.state.dataAusente),
        });
      }

    },
    cancel: (props, monitor) => monitor.getItem(),
  });

  return (
    <div className='fila-vendedores-disponiveis-container'>

      {mostrarAdicionarVendedor && (

        <AdicionarVendedorFilaWrapper
          mostrarAdicionarVendedor={mostrarAdicionarVendedor}
          setMostrarAdicionarVendedor={setMostrarAdicionarVendedor}
          dataFila={props.state.dataFila}
          dataAusente={props.state.dataAusente}
        />
      )}


      {mostrarEncerramento && (

        <EncerrarAtendimento
          self={props.self}
          visible={true}
          vendedor={vendedor}
          motivos={props.state.motivos}
          onCancel={fecharEncerrarAtendimento}
          tipoEncerramento={tipoEncerramento}
        />
      )}


      {
        diarioVendedor.id === '' || diarioVendedor.id === undefined ? null
          :

          <DiarioVendedor
            id={diarioVendedor.id}
            nome={diarioVendedor.nome}
            empresaId={diarioVendedor.empresaId}
            visible={diarioVendedor.visible}
            vendedores={diarioVendedor.vendedores}
            exigirSenhaDesempenhoVendas={props.self.state.config.exigirSenhaDesempenhoVendas}
            cpf={diarioVendedor.cpf}
            onClose={() => {
              setDiarioVendedor({
                diarioVendedor: {
                  id: "",
                  nome: "",
                  empresaId: "",
                  visible: false,
                },
              });
            }}
          />
      }


      <SairDaFila
        visible={mostrarSaidaVendedor}
        destroyOnClose={true}
        vendedor={vendedor}
        onSubmit={async (razao) => {
          return new Promise(async (resolve, reject) => {
            await ausentarVendedor(props.self, vendedor, razao.type, razao.value);
            resolve(true);
            setMostrarSaidaVendedor(false);
          });
        }}
        onCancel={() => {
          setMostrarSaidaVendedor(false);
        }}
      >
      </SairDaFila>

      {/* {!props.state.loading && !props.state.aguardarProcessamentoFila ? ( */}

      <div
        className='fila-vendedores-disponiveis'
        ref={drop}
        onClick={(event) => { event.stopPropagation(); abrirModalAdicionarVendedor(); }}
      >

        <div className='lista-vendedores-content'>
          <List
            className='lista-vendedores-diposniveis'
            loading={props.state.loading || props.state.aguardarProcessamentoFila}
            locale={{
              emptyText: (
                <Empty
                  imageStyle={{
                    height: 48,
                  }}
                  className='adicionar-vendedores-empty-container'
                  image={<PlusCircleOutlined style={{ fontSize: 48 }} />}
                  description={
                    <div>
                      <strong className='adicionar-vendedores-empty-container-strong'>
                        Entrar na fila de atendimento</strong>
                      <br></br>
                      <span className='adicionar-vendedores-empty-container-description'>
                        Toque para adicionar ou arraste alguém na lista abaixo
                        para colocá-lo na fila de atendimento
                      </span>
                    </div>}
                ></Empty>
              ),
            }}
            dataSource={props.state.dataFila}
            renderItem={(item, index) => (
              <List.Item
                key={item.id}
                style={{
                  border: "ridge 1px",
                  backgroundColor: item.backgroundColor,
                }}
                className={index === ultimoItemIndex ? 'ultimo-item' : ''}
              >
                <List.Item.Meta
                  avatar={<Avatar size={52} src={item.avatar} />}
                  className='list-item-meta-avatar'
                  title={formatarNome(item.apelido && item.apelido !== '' ? item.apelido : item.nome)}
                  description={(
                    <div>
                      <p>
                        {item.status.descricao}
                      </p>
                      <Badge
                        className="sellerBadge"
                        count={<IconFont type={item.status.icon} size={20} />}
                      >
                        <CustomDateFNS date={item.status.data} interval={15} />
                      </Badge>
                    </div>
                  )}
                />
                {/* {item.status.emAtendimento && (
                  <Button
                    className='btnAdicionarAtendimento'
                    type="link"
                    shape="circle"
                    disabled={props.state.loading}
                    onClick={() =>
                      adicionarAtendimento({ user: item })
                    }
                  >
                    <PlusCircleOutlined className="iconAdd" />
                  </Button>
                )} */}
                {!item.status.emAtendimento && (
                  <div className='sellerButtons'>
                    <Button
                      className='btnIniciarAtendimento'
                      type="primary"
                      disabled={props.state.loading}
                      onClick={() =>
                        btnIniciarAtendimentoClick({ user: item })
                      }
                    >
                      Atender
                    </Button>

                    <MenuMaisOpcoes onItemClick={onMenuMaisOpcoesClick} user={item} />
                  </div>
                )}
                {item.status.emAtendimento && (
                  <div className='sellerButtons'>

                    <BotaoAcaoFlutuante
                      user={item}
                      onBotaoAcaoClick={onBotaoAcaoClick}
                    />


                    {/* <Button
                      className='btnEncerrarAtendimento'
                      type="danger"
                      disabled={props.state.motivos.length === 0 || !props.state.produtos || props.state.loading}
                      onClick={() => btnEncerrarAtendimentoClick({ user: item })}
                    >
                      {labelEncerrarAtendimento}
                    </Button> */}

                  </div>
                )}
              </List.Item>
            )}
          />
        </div>
      </div>
      {/* ) : (
         <div className='div-vendedores-waiting-load'>
           <Spin indicator={antIcon} size='large' />
           <br></br>
           <label>Aguarde...</label>
         </div>
       )} */}

    </div>




    // <div className='fila-vendedores-disponiveis'>

    //   {mostrarAdicionarVendedor && (

    //     <AdicionarVendedorFilaWrapper
    //       mostrarAdicionarVendedor={mostrarAdicionarVendedor}
    //       setMostrarAdicionarVendedor={setMostrarAdicionarVendedor}
    //       dataFila={props.state.dataFila}
    //       dataAusente={props.state.dataAusente}
    //     />
    //   )}


    //   <SairDaFila
    //     visible={mostrarSaidaVendedor}
    //     destroyOnClose={true}
    //     vendedor={vendedor}
    //     onSubmit={async (razao) => {
    //       return new Promise(async (resolve, reject) => {
    //         await ausentarVendedor(props.self, vendedor, razao.type, razao.value);
    //         resolve(true);
    //         setMostrarSaidaVendedor(false);
    //       });
    //     }}
    //     onCancel={() => {
    //       setMostrarSaidaVendedor(false);
    //     }}
    //   >
    //   </SairDaFila>

    //   <div ref={drop}>

    //     <List
    //       loading={props.state.aguardarProcessamentoFila}
    //       locale={{
    //         emptyText: (
    //           <Empty
    //             imageStyle={{
    //               height: 48,
    //             }}
    //             className='adicionar-vendedores-empty-container'
    //             image={<PlusCircleOutlined style={{ fontSize: 48 }} />}
    //             description={
    //               <div>
    //                 <strong className='adicionar-vendedores-empty-container-strong'>Entrar na fila de atendimento</strong>
    //                 <br></br>
    //                 <span className='adicionar-vendedores-empty-container-description'>Toque para adicionar alguém na fila de atendimento</span>
    //               </div>}
    //           ></Empty>
    //         ),
    //       }}
    //       dataSource={props.state.dataFila}
    //       onClick={(event) => { event.stopPropagation(); abrirModalAdicionarVendedor(); }}
    //       renderItem={(item) => (
    //         <List.Item
    //           key={item.id}
    //           style={{
    //             border: "ridge 1px",
    //             backgroundColor: item.backgroundColor,
    //           }}
    //         >
    //           <List.Item.Meta
    //             avatar={<Avatar size={52} src={item.avatar} />}
    //             title={formatarNome(item.apelido && item.apelido !== '' ? item.apelido : item.nome)}
    //             description={(
    //               <div>
    //                 <p>
    //                   {item.status.descricao}
    //                 </p>

    //                 <Badge
    //                   className="sellerBadge"
    //                   count={<IconFont type={item.status.icon} size={20} />}
    //                 >
    //                   <CustomDateFNS date={item.status.data} interval={15} />
    //                 </Badge>
    //               </div>
    //             )}
    //           />

    //           {item.status.emAtendimento && (
    //             <Button
    //               className='btnAdd'
    //               type="link"
    //               shape="circle"
    //               disabled={props.state.loading}
    //               onClick={() =>
    //                 adicionarAtendimento({ user: item })
    //               }
    //             >
    //               <PlusCircleOutlined className="iconAdd" />
    //             </Button>
    //           )}

    //           {!item.status.emAtendimento && (
    //             <div className='sellerButtons'>
    //               <Button
    //                 className='btnAtender'
    //                 type="primary"
    //                 disabled={props.state.loading}
    //                 onClick={() =>
    //                   btnIniciarAtendimentoClick({ user: item })
    //                 }
    //               >
    //                 Atender
    //               </Button>

    //               <Button
    //                 className='btnExit'
    //                 disabled={props.state.loading}
    //                 onClick={() => btnSairClick({ user: item })}
    //               >
    //                 {"Sair"}
    //               </Button>
    //             </div>
    //           )}

    //           {item.status.emAtendimento && (
    //             <div className='sellerButtons'>
    //               <Button
    //                 className='btnSuccess'
    //                 disabled={!props.state.produtos || props.state.loading}
    //                 onClick={() => btnVendeuClick({ user: item })}
    //               >
    //                 Vendi
    //               </Button>

    //               <Button
    //                 className='btnUnsuccess'
    //                 type="danger"
    //                 disabled={props.state.motivos.length === 0 || !props.state.produtos || props.state.loading}
    //                 onClick={() => btnNaoVendeuClick({ user: item })}
    //               >
    //                 Não vendi
    //               </Button>
    //             </div>
    //           )}

    //           <Button
    //             icon={<LineChartOutlined />}
    //             className="btnRankingVendedor"
    //             disabled={props.state.loading}
    //             onClick={() =>
    //               btnVerRankingVendedor({ user: item })
    //             }
    //           />

    //         </List.Item>
    //       )}
    //     />

    //   </div>
    // </div>
  );
}

export default VendedoresDisponiveis;
