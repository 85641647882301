import React from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SpeedTest from "../../components/speedtest/speedtest";
import { withRouter } from "react-router";
import "antd/dist/antd.css";
import {
  carregarVendedores,
  entrarNaFila,
  iniciarAtendimento,
  finalizarAtendimento,
  ausentarVendedor,
  desconectar,
  listenVendChange,
  carregarTabelasAuxiliares,
  atualizarColecaoVendedores,
  habilitaSnapshotUpdate,
  carregarDadosRanking,
  extrairListaRanking,
  loadConfig,
  editAtendimento,
} from "./Functions-Fila";

import { Select, Modal, message } from 'antd';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Fila.css";
import { currentUser } from "../../components/auth/auth-provider";
import SuperSelect from "../../components/VirtualSelect";
import {
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import FilaHeader from "../../components/Fila/FilaHeader";
import VendedoresDisponiveis from "../../components/Fila/VendedoresDisponiveis";
import FilaFooter from "../../components/Fila/FilaFooter";
import SelecionarSetor from "../../components/SelecionarSetor/SelecionarSetor";
import Guide from "../../components/JoyRide/Guide";
import filaStorage from "../../components/localstorage/localstorage";
import mensagem from "../../components/message/Message";


const Option = SuperSelect.Option;
const { confirm } = Modal;
const SETOR_PADRAO = 'Padrão';


class Fila extends React.Component {
  state = {
    dataAusente: [],
    dataFila: [],
    loading: true,
    loadingFirst: true,
    hasMore: true,
    render: "",
    user: undefined,
    userLogged: "Carregando...",
    ignoreSnapshotUpdate: true,
    produtos: [],
    produtosComSelect: [],
    motivos: [],
    motivosComSelect: [],
    aguardarProcessamentoFila: false,
    rankingVisivel: false,
    vendedores: [],
    ranking: [],
    config: {
      registroProdutos: true,
      vendaTemporaria: false,
      exibirDesempenhoVendas: false,
      exigirSenhaDesempenhoVendas: false,
    },
    diarioVendedor: {
      id: "",
      nome: "",
      empresaId: "",
      visible: false,
    },
    saidaProps: {
      user: null,
    },
    exibirModalParaSelecionarSetor: false,
    setores: [],
    setorSelecionado: '',
    empresaLogin: {
      setor: SETOR_PADRAO,
      cnpj: '',
    },
    vendedorQueClicouBotao: undefined,
    abrirMenu: false,
  };

  constructor(props) {
    super(props);

    this.saidaElement = React.createRef();
    this.motivoElement = React.createRef();
    this.produtosElement = React.createRef();

    this.loadConfig = loadConfig.bind(this);
    this.carregarVendedores = carregarVendedores.bind(this);
    this.entrarNaFila = entrarNaFila.bind(this);
    this.iniciarAtendimento = iniciarAtendimento.bind(this);
    this.finalizarAtendimento = finalizarAtendimento.bind(this);
    this.ausentarVendedor = ausentarVendedor.bind(this);
    this.btnDesconectarClick = this.btnDesconectarClick.bind(this);
    this.setSelecionarSetor = this.setSelecionarSetor.bind(this);
    this.exibirRanking = this.exibirRanking.bind(this);
    this.fecharRanking = this.fecharRanking.bind(this);
    this.handleInfiniteOnLoad = this.handleInfiniteOnLoad.bind(this);
    this.listenVendChange = listenVendChange.bind(this);
    this.auxiliaresCarregados = this.auxiliaresCarregados.bind(this);
    this.carregarTabelasAuxiliares = carregarTabelasAuxiliares.bind(this);
    this.atualizarColecaoVendedores = atualizarColecaoVendedores.bind(this);
    this.habilitaSnapshotUpdate = habilitaSnapshotUpdate.bind(this);
    this.carregarDadosRanking = carregarDadosRanking.bind(this);
    this.handleSaidaFinish = this.handleSaidaFinish.bind(this);
    this.setSetor = this.setSetor.bind(this);
    this.cargaDeDados = this.cargaDeDados.bind(this);
    this.selectSetorChange = this.selectSetorChange.bind(this);

    this.retornarPrimeiroAndUltimoNome = this.retornarPrimeiroAndUltimoNome.bind(this);
    this.handleAvatarClick = this.handleAvatarClick.bind(this);
    this.exibirGuia = this.exibirGuia.bind(this);
    this.callbackGuide = this.callbackGuide.bind(this);
  }

  async componentDidMount() {
    this.cargaDeDados(true);
  }

  async cargaDeDados(primeiraCarga) {
    this.fechAusenteData(async (data) => {

      setTimeout(() => {
        this.setState({ loading: false, loadingFirst: false });
      }, 1500);

      this.setState({
        dataAusente: data.ausente,
        dataFila: data.fila,
      });

      const user = await currentUser();

      const empresaLogin = await this.state.empresaLogin;
      empresaLogin.cnpj = user.cnpj;

      if (!user) {
        this.btnDesconectarClick();
        return;
      }
      const setores = [];

      const { Option } = Select;
      if (user.setores) {
        user.setores.forEach((item) => {
          setores.push(<Option key={item}>{item}</Option>);
        });
      }

      const setorSelecionado = filaStorage.get('setorSelecionado');
      if (setorSelecionado) {
        const empresaLogin = this.state.empresaLogin;
        empresaLogin.setor = setorSelecionado;    
        this.setState({ empresaLogin, setorSelecionado });
      }

      const exibirModalParaSelecionarSetor = !setorSelecionado &&
        (primeiraCarga && user.setores && user.setores.length > 0);
      this.setState({
        user,
        userLogged: `${user.login.login}`,
        exibirModalParaSelecionarSetor,
        setores,
        empresaLogin,
      });

      if (this.props.location.state) {
        const stt = JSON.parse(this.props.location.state);
        this.auxiliaresCarregados(stt.produtos, stt.motivos);
      } else {
        this.carregarTabelasAuxiliares(this.auxiliaresCarregados);
      }
    });

    const config = await this.loadConfig();
    let configFila = {
      registroProdutos: true,
      vendaTemporaria: false
    };

    if (config !== undefined && config !== null && config.fila) {
      configFila.registroProdutos = config.fila.atendimento.registroProdutos;
    }

    if (config !== undefined && config !== null && config.fila && config.fila.atendimento.vendaTemporaria !== undefined) {
      configFila.vendaTemporaria = config.fila.atendimento.vendaTemporaria;
    }

    this.setState({ config: configFila });

    this.listenVendChange();
  }



  handleInfiniteOnLoad() {
    console.log("handleInfiniteOnLoad");
  }

  selectSetorChange(setorSelecionado) {
    this.setState({ dataFila: [], setorSelecionado });
    this.cargaDeDados(false);
  }

  async setSetor(setorSelecionado) {
    const empresaLogin = this.state.empresaLogin;
    empresaLogin.setor = setorSelecionado;
    this.setState({
      setorSelecionado,
      empresaLogin,
      exibirModalParaSelecionarSetor: false,
      dataAusente: [],
      dataFila: []
    });
    this.listenVendChange();
    filaStorage.set('setorSelecionado', setorSelecionado);
  }

  fechAusenteData = async (callback) => {
    const res = await this.carregarVendedores();
    callback(res);
  };

  async auxiliaresCarregados(produtos, motivos) {
    const listaMotivos = [];

    for (let i = 0; i < motivos.length; i++) {
      const nome = motivos[i].value.toLowerCase();
      listaMotivos.push(
        <Option key={motivos[i].key} value={nome}>
          {nome}
        </Option>
      );
    }

    let warningMessage = undefined;
    if (motivos.length === 0 && !produtos) {
      warningMessage = 'Identificamos que não existe nenhum motivo e produto cadastrados, devido a isso os botões "Vendi" e "Não Vendi" ficarão bloqueados. Por favor, entre em contato com o seu gestor para efetuar esses cadastros.'
    } else if (!produtos) {
      warningMessage = 'Identificamos que não existe nenhum produto cadastrado, devido a isso os botões "Vendi" e "Não Vendi" ficarão bloqueados. Por favor, entre em contato com o seu gestor para efetuar esses cadastros.'
    } else if (motivos.length === 0) {
      warningMessage = 'Identificamos que não existe nenhum motivo cadastrado, devido a isso o botão "Não Vendi" ficará bloqueado. Por favor, entre em contato com o seu gestor para efetuar esses cadastros.'
    }

    if (warningMessage) {
      Modal.warning({
        title: 'Aviso',
        content: warningMessage,
      });
    }

    this.setState({
      produtos,
      motivos,
      motivosComSelect: listaMotivos,
    });
  }

  btnEntrarNaFila(user, status) {
    this.entrarNaFila(this, user);
  }

  btnIniciarAtendimentoClick(obj) {
    const a_user = obj.user;
    iniciarAtendimento(this, a_user);
  }

  btnVerRankingVendedor(obj) {
    const user = obj.user;
    this.setState({
      diarioVendedor: {
        id: user.id,
        nome: user.nome,
        empresaId: this.state.user.id,
        visible: true,
        vendedores: this.state.dataFila.concat(this.state.dataAusente),
      },
    });

    setTimeout(() => {
      this.setState({ loading: false });
    }, 3000);


  }

  makeid() {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 10; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  async btnVendeuClick(obj) {
    this.setState({ vendedorQueClicouBotao: obj.user });
    const args = {
      self: this,
      user: obj.user,
      vendeu: true,
    };
    this.produtosElement.current.show(finalizarAtendimento, args);
  }

  btnNaoVendeuClick(obj) {
    const args = {
      self: this,
      user: obj.user,
      vendeu: false,
    };
    this.motivoElement.current.show(finalizarAtendimento, args);
  }

  adicionarAtendimento(obj) {
    const args = {
      self: this,
      user: obj.user,
    };

    confirm({
      title: "Adicionar atendimento",
      icon: <ExclamationCircleOutlined />,
      content:
        "Adicionar atendimento simultâneo para " + obj.user.apelido + "?",
      onOk() {
        editAtendimento(args);
      },
      onCancel() {
      },
    });
  }

  btnAcaoClick(obj) {
    const a_user = obj.user;
    if (!a_user.status.emAtendimento) {
      iniciarAtendimento(this, a_user);
    } else {
      finalizarAtendimento(this, a_user);
    }
  }

  btnSairClick(obj) {
    this.setState({ saidaProps: { user: obj.user } });
    // this.saidaElement.current.show(ausentarVendedor, [this, obj.user]);
  }

  handleSaidaFinish(user, icon, reason) {
    ausentarVendedor(this, user, icon, reason);
    this.setState({ saidaProps: { user: null } });
  }

  async exibirRanking(modificador) {
    const vendedores = this.state.dataFila.concat(this.state.dataAusente);
    this.setState({ loading: true, vendedores: vendedores });

    const dadosObject = await this.carregarDadosRanking(modificador);
    const listaRanking = await extrairListaRanking(dadosObject);

    this.setState({
      ranking: listaRanking,
      rankingVisivel: true,
    });
  }

  fecharRanking() {
    this.setState({ rankingVisivel: false, loading: false });
  }

  btnDesconectarClick() {
    setTimeout(() => {
      desconectar();
    }, 600);
  }

  setSelecionarSetor() {
    if (this.state.setores.length === 0 && this.state.empresaLogin.setor === SETOR_PADRAO) {
      message.info('Não existem setores cadastrados para a sua loja.');
      return;
    }
    this.setState({ exibirModalParaSelecionarSetor: true });
  }

  retornarPrimeiroAndUltimoNome(fullName) {
    const nameArray = fullName.split(' ');
    const firstName = nameArray[0];
    const lastName = nameArray[nameArray.length - 1];
    return `${firstName} ${lastName}`;
  }

  handleAvatarClick() {
    this.setState({ abrirMenu: !this.state.abrirMenu });
  }

  exibirGuia() {
    return false;
    // const exibir = (filaStorage.get('exibirGuia') === null);
    // return exibir && (this.state.dataAusente.length > 0 || this.state.dataFila.length > 0);
  }

  callbackGuide() {
    filaStorage.set('exibirGuia', false);    
  }


  render() {

    return (
      <div className="layout-container">

        {this.exibirGuia() ? (
          <Guide
          callbackGuide={this.callbackGuide}
          ></Guide>
        ) : (
          ''
        )}

        <DndProvider backend={HTML5Backend}>
          {this.state.exibirModalParaSelecionarSetor ? (
            <SelecionarSetor
              open={this.state.exibirModalParaSelecionarSetor}
              setores={this.state.setores}
              setSetor={this.setSetor}
            />
          ) : (
            ''
          )}

          <div className="layout-header">
            <FilaHeader
              dataAusente={this.state.dataAusente}
              dataFila={this.state.dataFila}
              setDesconectar={this.btnDesconectarClick.bind(this)}
              setSelecionarSetor={this.setSelecionarSetor.bind(this)}
              empresaLogin={this.state.empresaLogin}
            ></FilaHeader>
          </div>


          <div className="layout-content">
            <div className="layout-component-wrapper">
              <VendedoresDisponiveis
                state={this.state}
                self={this}
              ></VendedoresDisponiveis>
            </div>
          </div>

          {this.state.dataAusente.length > 0 && !this.state.loadingFirst ? (
            <div className="layout-footer">
              <div className="layout-component-wrapper">
                <FilaFooter
                  ausentes={this.state.dataAusente}
                >
                </FilaFooter>
              </div>
            </div>
          ) : (
            ''
          )}

          <div className="layout-footer-fixo">
            <SpeedTest className='layout-footer-fixo'></SpeedTest>
          </div>
        </DndProvider>
      </div>
    );
  }
}

// class AlterarSenha extends React.Component {
//   state = {
//     visible: false,
//     confirmLoading: false,
//     usuario: undefined,
//     password: '',
//   }

//   constructor(props) {
//     super(props);
//     this.handleOk = this.handleOk.bind(this);
//   }

//   async componentDidMount() {
//     let visible = false;
//     let usuario = undefined;

//     const cachePassword = filaStorage.get(PASSWORD_UPDATED);
//     if (!Boolean(cachePassword)) {
//       const user = await currentUser();
//       usuario = await usuarioDB.getByUserId(user.id);
//       if (!usuario) {
//         usuario = {
//           ativo: true,
//           gestorId: user.gestorId,
//           id: user.id,
//           tipo: 'Empresa'
//         }
//       }

//       visible = !usuario || !usuario.lastPasswordUpdate;
//       if (!visible) {
//         filaStorage.set(PASSWORD_UPDATED, true);
//       }
//     }

//     this.setState({ visible, usuario });
//   }

//   async handleOk(password) {
//     const usuario = this.state.usuario;
//     const failMessage = 'Erro ao tentar salvar a nova senha. Tente novamente';

//     const isOk = await updatePassword(usuario.id, password);
//     if (!isOk) {
//       message.error(failMessage);
//       return;
//     }

//     usuario.lastPasswordUpdate = new Date();

//     let res = undefined;
//     if (usuario.key) {
//       res = await usuarioDB.update(usuario.key, usuario);
//     } else {
//       res = await usuarioDB.add(usuario);
//     }

//     if (!res.recorded && !res.updated) {
//       message.error(failMessage);
//       return;
//     }

//     filaStorage.set(PASSWORD_UPDATED, true);
//     this.setState({ visible: false });
//   }

//   render() {
//     return (
//       <Modal
//         title='Nova senha necessária para continuar'
//         visible={this.state.visible}
//         destroyOnClose={true}
//         confirmLoading={this.state.confirmLoading}
//         centered={true}
//         onOk={this.handleOk}
//         footer={null}
//         closable={false}>

//         <AlterarSenhaFila handleOk={this.handleOk}>
//         </AlterarSenhaFila>
//       </Modal>
//     )
//   }
// }

export default withRouter(Fila);
