import React from 'react';
import { Select, Modal } from 'antd';


function SelecionarSetor(props) {

    const handleChange = (value) => {
        if (props.setSetor) {
            props.setSetor(value);
        }
    };


    return (
        <div>
            <Modal
                title="Escolha o setor desta estação"
                open={props.open}
                //onOk={handleOk}
                cancelButtonProps={{ style: { display: 'none' } }}
                closable={false}
            >
                <Select
                    mode="single"
                    style={{ width: "100%" }}
                    placeholder="Selecione um setor"
                    onChange={handleChange}
                >
                    {props.setores}
                </Select>
            </Modal>
        </div>
    );

}

export default SelecionarSetor;