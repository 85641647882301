import React from "react";
import { Button, Radio } from "antd";
import IconFont from "../../components/iconFont/IconFont";
import { withRouter } from "react-router";
import history from "../../history";
import { carregarDadosRanking } from "./Functions-Ranking";
import { Row, Col, Badge, Card, Avatar, List, Progress } from "antd";
import "./Ranking.css";

// const DemoBox = (props) => (
//   <p className={`height-${props.value}`} style={{ background: "#DDD" }}>
//     {props.children}
//   </p>
// );

class Ranking extends React.Component {
  state = {
    userLogged: "",
    produtos: [],
    motivos: [],
    vendedores: [],
    chartData: {
      labels: [],
      datasets: [],
    },
    top3: {
      primeiro: {
        nome: "",
        avatar: "",
        valor: 1,
      },
      segundo: {
        nome: "",
        avatar: "",
        valor: 1,
      },
      terceiro: {
        nome: "",
        avatar: "",
        valor: 1,
      },
    },
  };

  constructor(props) {
    super(props);

    this.btnVoltarFilaClick = this.btnVoltarFilaClick.bind(this);
    this.carregarDadosRanking = carregarDadosRanking.bind(this);
  }

  async componentDidMount() {
    const state = JSON.parse(window.history.state.state);
    this.setState({
      vendedores: state.vendedores,
      userLogged: state.userLogged,
    });

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });

    await this.configurarGrafico();

    // if (this.props.location.state) {
    //     const stt = JSON.parse(this.props.location.state);

    //     if (stt) {
    //         this.setState({ produtos: stt.produtos, motivos: stt.motivos, userLogged: stt.userLogged });
    //     }

    //     this.configurarGrafico();
    // }
    // else {
    //     history.push(`/fila`);
    //     history.go();
    // }
  }

  async configurarGrafico() {
    const dados = await this.carregarDadosRanking();
    this.setState({ chartData: dados });

    const top3 = {
      primeiro: {
        nome: dados.labels[0],
        avatar: dados.avatar[0],
        valor: dados.datasets[0].data[0],
      },
      segundo: {
        nome: dados.labels[1],
        avatar: dados.avatar[1],
        valor: dados.datasets[0].data[1],
      },
      terceiro: {
        nome: dados.labels[2],
        avatar: dados.avatar[2],
        valor: dados.datasets[0].data[2],
      },
    };

    this.setState({ top3: top3 });
  }

  btnVoltarFilaClick() {
    const data = JSON.stringify({
      produtos: this.state.produtos,
      motivos: this.state.motivos,
    });

    history.push(`/fila`, data);
    history.go();
  }

  onChange(e) {
    console.log(`radio checked:${e.target.value}`);
  }

  renderList(dados) {
    return (
      <List.Item>
        <List.Item.Meta
          avatar={
            <Badge
              // count={3}
              style={{ backgroundColor: "#6A3805" }}
            >
              <Avatar
                size={40}
                style={{
                  color: "#f56a00",
                  backgroundColor: "#fde3cf",
                }}
                src={dados.avatar}
              ></Avatar>
            </Badge>
          }
          title={
            <div>
              {dados.nome}
              <Progress
                percent={(
                  parseFloat(dados.valor / this.state.top3.primeiro.valor) * 100
                ).toFixed(0)}
                size="small"
                strokeColor="rgba(0, 143, 251, 0.35)"
                // status="active"
                showInfo={false}
              />
            </div>
          }
          description={<span>R$ {dados.valor}</span>}
        />
      </List.Item>
    );
  }

  render() {
    let listItems = [];

    for (let i = 3; i < this.state.chartData.datasets[0].data.length; i++) {
      const item = {
        avatar: this.state.chartData.avatar[i],
        nome: this.state.chartData.labels[i],
        valor: this.state.chartData.datasets[0].data[i],
      };

      listItems.push(item);
    }

    return (
      <div className="wrapper">
        <div className="content">
          <div className="contentHeader">
            <div className="divImg">
              <img
                src="https://storage.googleapis.com/bluve-bucket/b_oficial.png"
                alt="Bluve"
              ></img>
            </div>

            <div className="contentHeaderLeft">{this.state.userLogged}</div>

            <div className="contentHeaderRight">
              <Button className="btnBluve" onClick={this.btnVoltarFilaClick}>
                <IconFont type={"people_alt"} size={14}></IconFont>
                &nbsp; Fila
              </Button>
            </div>
          </div>

          <div className="container">
            <Radio.Group onChange={this.onChange} defaultValue="a">
              <Radio.Button value="7">7 Dias</Radio.Button>
              <Radio.Button value="14">14 Dias</Radio.Button>
              <Radio.Button value="21">21 Dias</Radio.Button>
              <Radio.Button value="30">30 Dias</Radio.Button>
            </Radio.Group>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24}>
                <Card>
                  <List>
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Badge
                            count={1}
                            style={{ backgroundColor: "#C9B037" }}
                          >
                            <Avatar
                              size={48}
                              style={{
                                color: "#f56a00",
                                backgroundColor: "#fde3cf",
                              }}
                              src={this.state.top3.primeiro.avatar}
                            ></Avatar>
                          </Badge>
                        }
                        title={
                          <div>
                            {this.state.top3.primeiro.nome}
                            <Progress
                              percent={100}
                              size="small"
                              strokeColor="#C9B037"
                              status="active"
                              showInfo={false}
                            />
                          </div>
                        }
                        description={
                          <span>R$ {this.state.top3.primeiro.valor}</span>
                        }
                      />
                    </List.Item>
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Badge
                            count={2}
                            style={{ backgroundColor: "#B4B4B4" }}
                          >
                            <Avatar
                              size={48}
                              style={{
                                color: "#f56a00",
                                backgroundColor: "#fde3cf",
                              }}
                              src={this.state.top3.segundo.avatar}
                            ></Avatar>
                          </Badge>
                        }
                        title={
                          <div>
                            {this.state.top3.segundo.nome}
                            <Progress
                              percent={(
                                parseFloat(
                                  this.state.top3.segundo.valor /
                                    this.state.top3.primeiro.valor
                                ) * 100
                              ).toFixed(0)}
                              size="small"
                              strokeColor="#B4B4B4"
                              status="active"
                              showInfo={false}
                            />
                          </div>
                        }
                        description={
                          <span>R$ {this.state.top3.segundo.valor}</span>
                        }
                      />
                    </List.Item>
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Badge
                            count={3}
                            style={{ backgroundColor: "#6A3805" }}
                          >
                            <Avatar
                              size={48}
                              style={{
                                color: "#f56a00",
                                backgroundColor: "#fde3cf",
                              }}
                              src={this.state.top3.terceiro.avatar}
                            ></Avatar>
                          </Badge>
                        }
                        title={
                          <div>
                            {this.state.top3.terceiro.nome}
                            <Progress
                              percent={(
                                parseFloat(
                                  this.state.top3.terceiro.valor /
                                    this.state.top3.primeiro.valor
                                ) * 100
                              ).toFixed(0)}
                              size="small"
                              strokeColor="#6A3805"
                              status="active"
                              showInfo={false}
                            />
                          </div>
                        }
                        description={
                          <span>R$ {this.state.top3.terceiro.valor}</span>
                        }
                      />
                    </List.Item>

                    {listItems.map(this.renderList)}
                  </List>
                </Card>
              </Col>

              {/* <Col span={15}>
                <Card>
                  <HorizontalBar
                    data={this.state.chartData}
                    width={300}
                    height={150}
                    options={{
                      title: {
                        display: false
                      },
                      legend: false,
                      tooltip: false,
                      scales: {
                        yAxes: [
                          {
                            gridLines: {
                              display: false
                            }
                          }
                        ],
                        xAxes: [
                          {
                            gridLines: {
                              display: false
                            },
                            ticks: {
                              display: false,
                              // min: 0,
                              beginAtZero: true
                              // callback: function(value, index, values) {
                              //   return "R$ " + value.toFixed(0).toString();
                              // }
                            }
                          }
                        ]
                      },
                      plugins: {
                        datalabels: {
                          align: function(context) {
                            var index = context.dataIndex;
                            var value = context.dataset.data[index];
                            var invert = Math.abs(value) <= 1;
                            return "end";
                            // return value < 1 ? "end" : "start";
                          },
                          anchor: "end",
                          backgroundColor: null,
                          borderColor: null,
                          borderRadius: 4,
                          borderWidth: 1,
                          color: "grey",
                          font: {
                            size: 11,
                            weight: "bold"
                          },
                          offset: 4,
                          padding: 0,
                          formatter: function(value) {
                            return (
                              "R$ " +
                              value
                                .toFixed(2)
                                .replace(".", ",")
                                .replace(/\d(?=(\d{3})+\.)/g, "$&.")
                            );
                          }
                        }
                      }
                    }}
                    //   options={{ maintainAspectRatio: false }}
                  />
                </Card>
              </Col> */}
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Ranking);
