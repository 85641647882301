import { firebaseImpl as fireBase, AUTH_URL } from '../../firebase/firebase';
import { authenticate, currentUser } from '../../components/auth/auth-provider';
import { message } from 'antd';
import qs from 'querystring';
import empresaDB from '../../dataManager/dtmEmpresa';


async function authUser(login, password) {

  return new Promise((resolve, reject) => {
    const url = AUTH_URL;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: qs.stringify({
        login,
        password,
      })
    }).then((response) => response.json())
      .then((responseData) => {
        if (responseData.code === 200) {
          resolve(responseData);
        }
        else {
          reject(responseData);
        }
      });
  });
}

export async function autenticar(login, password) {
  return new Promise((resolve) => {
    authUser(login, password)
      .then(resp => {
        fireBase.auth().signInWithCustomToken(resp.token)
          .then(async () => {
            const empresa = await empresaDB.getByLogin(login);
            resp.user.setores = empresa[0].setores;
            authenticate(resp.user);
            resolve(true);
          })
          .catch(error => {
            const msg = error.code === 'auth/user-disabled' ?
              'Senha ou email incorretos. Verifique.' :
              'Houve um erro inesperado.';

            message.error(msg);
            resolve(false);
          });
      })
      .catch(err => {
        message.error(err.message);
        resolve(false);
      });
  });

}

export async function redirectJustIfUserIsAuthenticated() {
  const user = await currentUser();
  if (user) {
    this.props.history.push('/fila');
  }
}

export async function updatePassword(id, password) {
  const empresa = await empresaDB.getById(id);
  if (!empresa) {
    return false;
  }
  empresa.login.password = password;
  await empresaDB.update(id, empresa);
  return true;
}
