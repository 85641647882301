import React, { useEffect, useState } from 'react';
import { Menu, Avatar, Dropdown, Space, Button } from 'antd';
import { DownOutlined, TeamOutlined, SkinOutlined } from '@ant-design/icons';
import AdicionarVendedorFilaWrapper from './AdicionarVendedorFilaWrapper';
import { currentUser } from '../auth/auth-provider';
import "./FilaHeader.css";


function FilaHeader(props) {

  const nomeProduto = 'Fila de Atendimento';

  const [nomeUsuario, setNomeUsuario] = useState('');
  const [labelFilaDeAtendimento, setLabelFilaDeAtendimento] = useState(nomeProduto);

  function handleResize() {
    if (window.innerWidth <= 400) {
      setLabelFilaDeAtendimento('Fila');
    } else {
      setLabelFilaDeAtendimento(nomeProduto);
    }
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  currentUser().then(user => {
    setNomeUsuario(user.nomeFantasia)
  });

  const [abrirMenu, setAbrirMenu] = useState(false);
  const [mostrarAdicionarVendedor, setMostrarAdicionarVendedor] = useState(false);

  function handleAvatarClick() {
    setAbrirMenu(!abrirMenu);
  }

  function abrirModalAdicionarVendedor() {
    handleAvatarClick();
    setMostrarAdicionarVendedor(true);
  }

  function selecionarSetor() {
    if (props.setSelecionarSetor) {
      props.setSelecionarSetor();
    }
  }

  function retornarPrimeiroAndUltimoNome(nomeCompleto) {
    const nameArray = nomeCompleto.split(' ');
    const firstName = nameArray[0];
    const lastName = nameArray[nameArray.length - 1];
    return `${firstName} ${lastName}`;
  }

  const items = [
    {
      key: '1',
      label: (
        <span className='menu-items-label'>Entrar na Fila</span>
      ),
      icon: <TeamOutlined className='menu-items-icone' />,
      onClick: () => abrirModalAdicionarVendedor()
    },
    // {
    //   key: '2',
    //   label: (
    //     <a className='menu-items-label'>Metas</a>
    //   ),
    //   icon: <LineChartOutlined className='menu-items-icone' />,
    //   onClick: () => abrirRanking()
    // },
    {
      key: '3',
      label: (
        <span className='menu-items-label'>Setores</span>
      ),
      icon: <SkinOutlined className='menu-items-icone' />,
      onClick: () => selecionarSetor()
    },
  ];


  const menu = (
    <Menu className='menu-loja'>
      <Menu.Item disabled className='menu-titulo-loja'>
        <strong>{nomeUsuario}</strong>
        <br />
        <div className='menu-titulo-div-setor'>
          <div className='menu-titulo-div-setor-left'>
            <span className='menu-titulo-span-setor'>Setor: {props.empresaLogin.setor}</span>
          </div>
          <div className='menu-titulo-div-setor-rigth'>
            <span className='menu-titulo-span-setor'>CNPJ: {props.empresaLogin.cnpj}</span>
          </div>
        </div>
      </Menu.Item>
      <Menu.Divider />
      {items.map((item) => (
        <Menu.Item key={item.key} onClick={item.onClick} danger={item.danger}>
          {item.icon} &nbsp;
          {item.label}
        </Menu.Item>
      ))}
      <Menu.Divider />
      <Menu.Item disabled className='menu-rodape'>
        <Button
          type="danger"
          className='menu-rodape-botao-sair'
          onClick={() => props.setDesconectar()}
        >Desconectar</Button>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item disabled className='menu-rodape'>
        <strong className='menu-rodape-label-versao'>Versão 2.1</strong>
      </Menu.Item>

    </Menu>
  );


  const initials = retornarPrimeiroAndUltimoNome(nomeUsuario)
    .split(" ")
    .map(palavra => palavra.substring(0, 1))
    .join("");

  return (
    <div className="fila-cabecalho">

      {mostrarAdicionarVendedor && (

        <AdicionarVendedorFilaWrapper
          mostrarAdicionarVendedor={mostrarAdicionarVendedor}
          setMostrarAdicionarVendedor={setMostrarAdicionarVendedor}
          dataFila={props.dataFila}
          dataAusente={props.dataAusente}
        />
      )}


      <div className="fila-logo">
        <span className="company-dot"></span>
        <span className='company-dot-label'>{labelFilaDeAtendimento}</span>
      </div>

      <img
        src={'https://storage.googleapis.com/bluve-bucket/logo-bluve-fila.PNG'}
        alt='logo-bluve'
        className='logo-bluve'
      />

      <div className="fila-menu">
        <div className="fila-dropdown-menus">
          <Avatar
            className="profileAvatar"
            size={44}
            style={{ margin: '1.75rem 1.25rem', flexShrink: 0 }}
            onClick={() => handleAvatarClick()}
          >
            {initials}
          </Avatar>
          <Dropdown overlay={menu} trigger={['click']}>
            <span onClick={(e) => e.preventDefault()}>
              <Space className="fila-profile-span">
                <label className="fila-profile-span-label">{nomeUsuario}</label>
                <DownOutlined />
              </Space>
            </span>
          </Dropdown>

        </div>
      </div>
    </div>
  );
}

export default FilaHeader;
