import React from 'react';
import { Tag, Card, Space, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import BuscarProdutos from '../../../BuscarProdutos/BuscarProdutos';
import './Produtos.css';

const ProdutosAtendimento = ({ foraMix, buscaForaMix, handleProdutoSelecionado, handleRemoverProduto, vendedor, produtos, tipoOperacao }) => {
    const formatarMoeda = (valor) => {
        valor = parseFloat(valor).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });
        return valor;
    }

    const calcularQuantidade = () => {
        return produtos.reduce((total, product) => total + product.qtd, 0);
    };

    const calcularTotal = () => {
        return produtos.reduce((total, product) => total + product.valorVenda * product.qtd, 0);
    };


    return (
        <div>
            <div className='steps-buscar-produtos'>
                <BuscarProdutos
                    foraMix={foraMix}
                    buscaForaMix={buscaForaMix}
                    gestorId={vendedor.gestorId}
                    onSelect={handleProdutoSelecionado}
                    tipoOperacao={tipoOperacao}
                />
                <br></br>
            </div>

            <div className='steps-div-cards'>
                <div className='steps-div-lista-produtos'>

                    {(produtos.length > 0) ? (
                        <Card>
                            {produtos.map((product) => (
                                <div key={product.nome} style={{ marginBottom: 8 }}>
                                    <Space>
                                        <Button
                                            type="link"
                                            danger
                                            icon={<DeleteOutlined />}
                                            onClick={() => handleRemoverProduto(product.key)}
                                        />
                                        <Tag
                                            color="#2db7f5"
                                        >{product.nome}
                                        </Tag>
                                        <Tag>
                                            Qtd: {product.qtd} x
                                        </Tag>

                                        <Tag
                                            color="#f50">
                                            {formatarMoeda(product.valorVenda)}
                                        </Tag>
                                    </Space>
                                </div>
                            ))}
                        </Card>

                    ) : (
                        <div className='steps-nenhum-produto-informado'>
                            <label>Nenhum produto selecionado</label>
                        </div>
                    )}


                </div>
                <Card title="Resumo">
                    <p>Total Quantidade: {calcularQuantidade()}</p>
                    <p>Total Valor: {formatarMoeda(calcularTotal())}</p>
                </Card>
            </div>
        </div>
    );


};

export default ProdutosAtendimento;