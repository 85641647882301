import React, { useEffect, useState, useRef } from "react";
import { Card, Modal, Statistic, List, Button, Row, Col, Input, Progress } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import Text from "antd/lib/typography/Text";
import 'antd/dist/antd.css';
import './DiarioVendedor.css';

import { fbDatabase } from "../../firebase/firebase";
import {
  getMonth,
  getYear,
  isAfter,
  isBefore,
  startOfDay,
  startOfMonth,
} from "date-fns";
import { endOfDay } from "date-fns/esm";
import moment from "moment";

function lerValor(valor) {

  if (valor === undefined || isNaN(Number(valor)) || valor === '') {
    return 0;
  }

  return Number(valor).toFixed(2);
}

const DiarioVendedor = ({
  id,
  nome,
  empresaId,
  visible,
  vendedores,
  exigirSenhaDesempenhoVendas,
  cpf,
  onClose,
}) => {
  const loading = useRef(false);
  const [ranking, setRanking] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [metaExiste, setMetaExiste] = useState(false);
  const [cpfVendedor, setCpfVendedor] = useState('');

  const cpfFormatado = cpf.replace(/\D/g, '').substring(0, 5);

  const onCpfChange = (e) => {
    const cpfInformado = e.target.value.replace(/\D/g, '');
    setCpfVendedor(cpfInformado);
  }

  const [vendedorData, setVendedorData] = useState({
    metaPeriodo: 0,
    diasExpediente: 0,
    metaDiaria: 0
  });

  const [historico, setHistorico] = useState({
    conversoes: 0,
    pecas: 0,
    atendimentos: 0,
    valor: 0,
  });

  const [historicoDia, setHistoricoDia] = useState({
    conversoes: 0,
    pecas: 0,
    atendimentos: 0,
    valor: 0,
  });

  let dataAtual = new Date();

  const dataInicial = startOfDay(startOfMonth(dataAtual));
  const mes = getMonth(dataInicial) + 1;
  const ano = getYear(dataInicial);

  const dataRanking = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);//isFirstDayOfMonth(dataAtual) ? subMonths(dataAtual, 1) : dataAtual;

  useEffect(() => {
    setIsModalVisible(visible);
  }, [visible]);

  useEffect(() => {

    async function loadData() {
      loading.current = true;
      const metaSnapshot = await fbDatabase
        .collection("metas")
        .where("empresaId", "==", empresaId)
        .where("ano", "==", ano)
        .where("mes", "==", mes)
        .get();

      setMetaExiste(!metaSnapshot.empty);

      const data = metaSnapshot.empty ? dataRanking : metaSnapshot.docs[0].data().inicio.toDate();

      const rankingSnapshot = await fbDatabase
        .collection("ranking")
        .where("empresaId", "==", empresaId)
        .where("data", ">=", data)
        .get();

      const dadosRanking = rankingSnapshot.docs.map((item) => item.data());

      let vendedorAvatar = "";
      let vendedorHistorico = {};
      let vendedorHistoricoDia = {};

      if (!rankingSnapshot.empty) {
        const reduceRanking = dadosRanking.reduce((aggr, current) => {
          const { vendedorId, atendimentos, data } = current;
          const vddor = vendedores.find((item) => item.id === vendedorId);
          let periodoIndex = 0;

          aggr[vendedorId] = aggr[vendedorId] || {
            valor: 0,
            valorFormatado: "",
            conversoes: 0,
            pecas: 0,
            atendimentos: 0,
            nome: vddor ? vddor.apelido : '',
            avatar: vddor ? vddor.avatar : '',
          };

          if (!metaSnapshot.empty) {
            periodoIndex = metaSnapshot.docs[0].data().periodos.findIndex(
              (periodo) =>
                isAfter(dataAtual, startOfDay(periodo.inicio.toDate())) &&
                isBefore(dataAtual, endOfDay(periodo.fim.toDate()))
            )
          }

          if (metaSnapshot.empty || data.toDate() >= metaSnapshot.docs[0].data().periodos[periodoIndex].inicio.toDate()) {

            aggr[vendedorId].valor += atendimentos.valor;
            aggr[vendedorId].conversoes += atendimentos.conversoes;
            aggr[vendedorId].pecas += atendimentos.pecas;
            aggr[vendedorId].atendimentos += atendimentos.quantidade;

            aggr[vendedorId].valorFormatado = aggr[vendedorId].valor > 0 ? new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(aggr[vendedorId].valor) : "-";
          }

          if (moment(data.toDate()).isSameOrAfter(moment(), 'day')) {
            //if (moment(data.toDate()).day() >= moment(new Date()).day()) {
            vendedorHistoricoDia[vendedorId] = {
              valor: 0,
              valorFormatado: '',
              conversoes: 0,
              pecas: 0,
              atendimentos: 0,
              nome: vddor ? vddor.apelido : '',
              avatar: vddor ? vddor.avatar : '',
            }

            vendedorHistoricoDia[vendedorId].valor = atendimentos.valor;
            vendedorHistoricoDia[vendedorId].conversoes = atendimentos.conversoes;
            vendedorHistoricoDia[vendedorId].pecas = atendimentos.pecas;
            vendedorHistoricoDia[vendedorId].atendimentos = atendimentos.quantidade;

            vendedorHistoricoDia[vendedorId].valorFormatado = vendedorHistoricoDia[vendedorId].valor > 0 ? new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(vendedorHistoricoDia[vendedorId].valor) : '-';
          }

          return aggr;
        }, {});

        if (!reduceRanking[id]) {
          const snapshotVendedor = await fbDatabase
            .collection("vendedores")
            .doc(id)
            .get();

          vendedorAvatar = snapshotVendedor.data().avatar;
          const nomeVendedor = snapshotVendedor.data().apelido;

          vendedorHistorico = {
            valor: 0,
            valorFormatado: "R$ 0,00",
            conversoes: 0,
            pecas: 0,
            atendimentos: 0,
            nome: nomeVendedor,
            avatar: vendedorAvatar,
          };

          reduceRanking[id] = vendedorHistorico;
        } else {
          vendedorAvatar = reduceRanking[id].avatar;
          vendedorHistorico = reduceRanking[id];
        }

        if (!vendedorHistoricoDia[id]) {
          const snapshotVendedor = await fbDatabase
            .collection("vendedores")
            .doc(id)
            .get();

          vendedorAvatar = snapshotVendedor.data().avatar;
          const nomeVendedor = snapshotVendedor.data().apelido;

          vendedorHistoricoDia[id] = {
            valor: 0,
            valorFormatado: 'R$ 0,00',
            conversoes: 0,
            pecas: 0,
            atendimentos: 0,
            nome: nomeVendedor,
            avatar: vendedorAvatar,
          };
        }

        let entries = Object.entries(reduceRanking);

        entries = entries.filter(entt => {
          return entt[1].nome !== '';
        });

        const resultadoOrdenado = entries.sort(
          (a, b) => b[1].valor - a[1].valor
        );

        let existe = false;

        const ranking = resultadoOrdenado.slice(0, 3).map((item, index) => {
          existe = item[0] === id ? true : existe;
          item[1].ordem = index + 1 + "º";
          return {
            id: item[0],
            ...item[1],
          }
        });

        if (!existe) {
          reduceRanking[id].id = id;
          reduceRanking[id].ordem = reduceRanking[id].valor === 0 ? "... " : (Object.values(resultadoOrdenado).findIndex(item => item[0] === id)) + 1 + "º";
          reduceRanking[id].ordem = reduceRanking[id].ordem !== "... " && reduceRanking[id].ordem !== "4º" ? "... " + reduceRanking[id].ordem : reduceRanking[id].ordem;
          ranking.push(reduceRanking[id])
        }

        setRanking(
          ranking
          /*resultadoOrdenado.slice(0, 3).map((item) => ({
            id: item[0],
            ...item[1],
          }))*/
        );
      }

      if (!metaSnapshot.empty) {
        const metaDocs = metaSnapshot.docs;
        const metaData = metaDocs[0].data();

        const periodoIndex = metaData.periodos.findIndex(
          (periodo) =>
            isAfter(dataAtual, startOfDay(periodo.inicio.toDate())) &&
            isBefore(dataAtual, endOfDay(periodo.fim.toDate()))
        );

        const { inicio, metaPorDia, vendedorPorDia } = metaData.periodos[periodoIndex];
        const vendedor = {
          id: "",
          metaPeriodo: 0,
          diasExpediente: 0,
          metaDiaria: 0,
          metaDiariaFormatado: "",
          historico: {
            conversoes: 0,
            pecas: 0,
            atendimentos: 0,
            valor: 0,
          },
          historicoDia: {
            conversoes: 0,
            pecas: 0,
            atendimentos: 0,
            valor: 0,
          },
        };

        const vendedorData = metaData.vendedores.find(
          (vendedor) => vendedor.id === id
        );

        const metaPeriodo = vendedorData.meta.periodos[periodoIndex];
        const diasExpediente = vendedorData.diasExpediente[periodoIndex];

        const dayIndex = moment().diff(moment(inicio.toDate()), 'days');

        const metaDiaria = metaPorDia !== undefined && dayIndex !== undefined ? parseFloat(metaPorDia[dayIndex] / vendedorPorDia[dayIndex]) : 0;

        const metaPeriodoFormatado = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(metaPeriodo);

        const metaDiariaFormatado = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(metaDiaria);

        const metaPorcentagem = Number(vendedorHistorico.valor * 100 / metaPeriodo).toFixed(2);

        const metaAcumuladaSnapshot = await fbDatabase
          .collection('meta-acumulada')
          .where('vendedorId', '==', id)
          .where('metaId', '==', metaDocs[0].id)
          .where('data', '<', startOfDay(new Date()))
          .get();

        let metaAcumulada = 0;

        if (!metaAcumuladaSnapshot.empty) {
          metaAcumuladaSnapshot.docs.forEach((item) => {
            const { vendido, metaDiaria } = item.data();

            if (vendido < metaDiaria) {
              metaAcumulada += metaDiaria - vendido;
            } else if (vendido > metaDiaria && metaAcumulada > 0) {
              metaAcumulada = vendido > metaAcumulada ? 0 : metaAcumulada - vendido;
            }
          });
        }

        const metaAcumuladaFormatado = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(metaAcumulada);


        Object.assign(vendedor, {
          diasExpediente,
          metaPeriodo,
          metaPeriodoFormatado,
          metaDiaria,
          metaDiariaFormatado,
          metaAcumulada,
          metaAcumuladaFormatado,
          metaPorcentagem,
          avatar: vendedorAvatar,
          historico: vendedorHistorico,
          historicoDia: vendedorHistoricoDia[id]
        });

        setVendedorData(vendedor);
        setHistorico(vendedor.historico);
        setHistoricoDia(vendedor.historicoDia);
      } else if (vendedorHistoricoDia[id] !== undefined) {
        const vendedor = vendedores.filter((vend) => vend.id === id)[0];
        vendedorHistorico.valorFormatado = vendedorHistorico.valorFormatado ?? '-'
        vendedorHistoricoDia[id].valorFormatado = vendedorHistoricoDia[id].valorFormatado ?? '-';

        Object.assign(vendedor, {
          historico: vendedorHistorico,
          historicoDia: vendedorHistoricoDia[id]
        })

        setVendedorData(vendedor)
        setHistorico(vendedor.historico)
        setHistoricoDia(vendedor.historicoDia)
      }

      loading.current = false;
    }

    loadData();

    // eslint-disable-next-line
  }, []);




  const handleOk = () => {
    onClose();
    setVendedorData({ diasExpediente: 0, metaDiaria: 0, metaPeriodo: 0 });
    setHistorico({ atendimentos: 0, conversoes: 0, pecas: 0, valor: 0 });
    setIsModalVisible(false);
  };

  const modalContent =
    <>
      <Avatar
        style={{ backgroundColor: "#fffff" }}
        src={vendedorData.avatar}
        size={{
          xs: 24,
          sm: 32,
          md: 40,
          lg: 64,
          xl: 80,
          xxl: 100,
        }}
      ></Avatar>
      <Text strong style={{ marginLeft: "10px" }}>
        {nome}
      </Text>
      {metaExiste && (
        <Card title='Meta do Período' bordered={false}>
          <Row style={{ display: 'flex', alignItems: 'center' }}>
            <Col flex={1}>
              <Progress
                type='circle'
                strokeColor='#1890ff'
                percent={lerValor(vendedorData.metaPorcentagem)}
              />
            </Col>

            <Col flex={1}>
              <Statistic
                title="Meta Período"
                value={vendedorData.metaPeriodoFormatado}
                precision={2}
              />
            </Col>

            <Col flex={1}>
              <Statistic
                formatter={(value) => value === 0 ? "-" : value}
                title='Total vendido'
                value={historico.valorFormatado}
                precision={2}
              />
            </Col>
          </Row>
        </Card>
      )}

      <Card title={vendedorData.metaDiaria > 0 && vendedorData.metaAcumulada > 0 ? 'Meta do Dia' : 'Relatório do Dia'} bordered={false}>
        <Row>
          {vendedorData.metaDiaria > 0 && (
            <Col flex={1}>
              <Statistic
                title="Meta do dia"
                value={vendedorData.metaDiariaFormatado}
                precision={2}
              />
            </Col>
          )}

          {vendedorData.metaAcumulada > 0 && (
            <Col flex={1}>
              <Statistic
                title="Meta Acumulada"
                value={vendedorData.metaAcumuladaFormatado}
                precision={2}
              />
            </Col>
          )}

          <Col flex={1}>
            <Statistic
              formatter={(value) => value === 0 ? "-" : value}
              title="Total vendido"
              value={historicoDia && historicoDia.valorFormatado ? historicoDia.valorFormatado : '--'}
              precision={2}
            />
          </Col>
        </Row>
      </Card>

      {!exigirSenhaDesempenhoVendas ? (
        <Card title="Vendedores - Top 3" bordered={false}>
          <List
            itemLayout="horizontal"
            dataSource={ranking}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar src={item.avatar} />}
                  title={item.id === id ?
                    <span><strong>{item.ordem + " - " + item.nome}</strong></span>
                    :
                    <span>{item.ordem + " - " + item.nome}</span>
                  }
                  description={<span>{item.valorFormatado}</span>}
                />
              </List.Item>
            )}
          />
        </Card>
      ) : ('')}

    </>

  return (
    <Modal
      className="modal-diario-vendedor"
      open={isModalVisible}
      destroyOnClose={true}
      closable={false}
      centered
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={handleOk}
        >
          Fechar
        </Button>,
      ]}
    >

      {exigirSenhaDesempenhoVendas && cpfVendedor !== cpfFormatado ? (<div>
        <label>Acesso ao Ranking</label>
        <Input.Password
          style={{ width: '100%' }}
          placeholder="Digite os 5 primeiros dígitos do seu CPF"
          onChange={(e) => onCpfChange(e)}
          value={cpfVendedor}
          maxLength={5}
        />

        {cpfVendedor.length === 5 && cpfVendedor !== cpfFormatado ? (
          <label style={{ marginTop: '10px', color: 'red' }}>CPF incorreto!</label>
        ) : ('')}


      </div>) : (modalContent)}

    </Modal>

  );
};

export default DiarioVendedor;
