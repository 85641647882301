import React from 'react';
import { Input as InputStr } from 'antd';

import './Indicacao.css';


const IndicacaoVenda = ({ handleIndicacaoChange, desabilitar }) => {
    return (
        <div>
            <label className='steps-label-opcional'> Informações opcionais </label>

            <br></br>

            <InputStr
                className='steps-inputstr'
                placeholder="Código da Indicação, CPF ou Telefone com DDD"
                inputMode='numeric'
                onChange={handleIndicacaoChange}
                disabled={desabilitar}
            ></InputStr>

        </div>
    );
}

export default IndicacaoVenda;