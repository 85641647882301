import React, { useState } from 'react';
import { Select } from 'antd';
import './ForaMix.css';


const { Option } = Select;


const ForaMix = ({ foraMix, handleForaMixChange }) => {

    return (
        <div>
            <span className='fora-mix-span-selecionar'>A loja trabalha com o produto?</span>
            <br></br><br></br>
            <Select
                placeholder="Informe"
                onChange={(value) => handleForaMixChange(value)}
                style={{ width: 200 }}
                value={foraMix}
            >
                <Option value={false}>Sim</Option>
                <Option value={true}>Não</Option>

            </Select>
        </div>
    );
}

export default ForaMix;